:root {
  --color-primary: #ff5a23;
  --color-secondary: #100a3d;
  --color-success: #46B450;
  --color-error: #DC3232;
  --color-info: #00A0D2;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: transparent;
}
body:before {
  content: "";
  background: url("/public/img/bg.jpg") center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -2;
}
body:after {
  content: "";
  background: var(--color-secondary);
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.7;
  z-index: -1;
}
body.loading:after {
  z-index: 9999;
}

.wrapper {
  z-index: 2;
  position: relative;
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "sidebar main";
  gap: 20px;
  padding: 20px;
  min-height: calc(100vh - 40px);
}
.wrapper.login-page {
  grid-template-columns: 1fr;
  grid-template-areas: "main";
}
.wrapper.login-page main.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapper.login-page main.box #login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapper.login-page main.box #login-form .row {
  margin: 4px;
}
.wrapper.login-page main.box #login-form .row input {
  min-width: 300px;
  font-size: 18px;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: #000000;
  padding: 4px;
}
.wrapper aside {
  grid-area: sidebar;
}
.wrapper main {
  grid-area: main;
}
.wrapper .box {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 8px;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar-content .logo {
  max-width: 100%;
  width: 100px;
}
.sidebar-content nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.sidebar-content nav ul li {
  padding: 2px 0;
}
.sidebar-content .logout {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #FFFFFF77;
  color: #FFFFFF;
}

.message {
  background-color: #FFFFFF;
  color: var(--color-secondary);
  padding: 8px;
  border-left: 4px solid var(--color-secondary);
  border-radius: 4px;
  margin: 20px 0;
  overflow: hidden;
  height: 100%;
  transition: all 500ms linear;
}
.message.message-info {
  color: var(--color-info);
  border-left: 4px solid var(--color-info);
}
.message.message-error {
  color: var(--color-error);
  border-left: 4px solid var(--color-error);
}
.message.message-success {
  color: var(--color-success);
  border-left: 4px solid var(--color-success);
}
.message.remove {
  height: 0;
  padding: 0;
  margin: 0;
}

.btn {
  display: inline-block;
  margin: 4px 2px;
  padding: 10px 24px;
  font-size: 1rem;
  background-color: var(--color-primary);
  border-radius: 8px;
  border: 2px solid var(--color-primary);
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.5s;
}
.btn:hover {
  border: 2px solid #FFFFFF77;
}

.check_unique {
  --width:40px;
  --height:20px;
}
.check_unique input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.check_unique label {
  cursor: pointer;
  text-indent: -9999px;
  width: var(--width);
  height: var(--height);
  background: var(--color-secondary);
  display: block;
  border-radius: var(--height);
  position: relative;
}
.check_unique label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 16px;
  transition: 0.3s;
}
.check_unique input:disabled + label {
  cursor: default;
  opacity: 0.5;
}
.check_unique input:checked + label {
  background: var(--color-primary);
}
.check_unique input:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
.check_unique label:active:after {
  width: 18px;
}

h1, .h1 {
  font-size: 30px;
}

h2, .h2 {
  font-size: 26px;
}

h3, .h3 {
  font-size: 22px;
}

h4, .h4 {
  font-size: 18px;
}

.list-group .entete {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.list-group .container {
  margin: 20px 0;
}
.list-group .container table {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
.list-group .container table tr {
  width: 100%;
}
.list-group .container table tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.1);
}
.list-group .container table tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.2);
}
.list-group .container table tr.groupe:not(:first-child):hover {
  background-color: transparent;
}
.list-group .container table tr td {
  padding: 6px 12px;
}
.list-group .container table tr td span {
  display: flex;
  align-items: center;
}
.list-group .container table tr td .flex-center {
  justify-content: center;
}
.list-group .container table tr td.locker span:after {
  content: "";
  width: 20px;
  height: 20px;
  margin-left: 10px;
  background: url("/public/img/unlock.svg") center no-repeat;
}
.list-group .container table tr td.lock-on {
  cursor: pointer;
}
.list-group .container table tr td.lock-on span:after {
  background: url("/public/img/lock.svg") center no-repeat;
}
.list-group .container table tr:first-child {
  background-color: rgba(255, 255, 255, 0.3);
}
.list-group .container table tr:first-child td {
  font-weight: bold;
  padding: 12px 12px;
  text-transform: uppercase;
}