:root {
	--color-primary: #ff5a23;
	--color-secondary: #100a3d;
	--color-success: #46B450;
	--color-error: #DC3232;
	--color-info: #00A0D2;
}

$black : #000000;
$white : #FFFFFF;
$grey : #777777;
$info : var(--color-info);
$success : var(--color-success);
$error : var(--color-error);
$primary : var(--color-primary);
$secondary : var(--color-secondary);

$radius : 8px;
$mini-radius : 4px;
