.list-group {
	.entete {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.container {
		margin: 20px 0;
		table{
			border-radius: $radius;
			overflow: hidden;
			width: 100%;
			tr{
				width: 100%;
				&:nth-child(odd){
					background-color: rgba($white, .1);
				}
				&:nth-child(even) {
					background-color: rgba($white, .2);
				}
				&.groupe:not(:first-child):hover {
					background-color: transparent;
				}
				td {
					padding: 6px 12px;
					span{
						display: flex;
						align-items: center;
					}
					.flex-center{
						justify-content: center;
					}

					&.locker span {
						&:after {
							content: "";
							width: 20px;
							height: 20px;
							margin-left: 10px;
							background: url("/public/img/unlock.svg") center no-repeat;
						}
					}
					&.lock-on {
						cursor: pointer;
						span{
							&:after{
								background: url("/public/img/lock.svg") center no-repeat;
							}
						}
					}
				}
				&:first-child{
					background-color: rgba($white, .3);
					td {
						font-weight: bold;
						padding: 12px 12px;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}
