body {
	font-family: Arial, Helvetica, sans-serif;
	color: $white;
	margin: 0;
	padding: 0;
	position: relative;
	background-color: transparent;

	&:before {
		content: "";
		background: url("/public/img/bg.jpg") center no-repeat;
		background-size: cover;
		background-attachment: fixed;
		height: 100vh;
		width: 100vw;
		position: fixed;
		left: 0;
		top: 0;
		z-index: -2;
	}

	&:after {
		content: "";
		background: $secondary;
		height: 100%;
		width: 100%;
		position: fixed;
		left: 0;
		top: 0;
		opacity: .7;
		z-index: -1;
	}
	&.loading{
		&:after{
			z-index: 9999;
		}
	}
}

.wrapper {
	z-index: 2;
	position: relative;
	display: grid;
	grid-template-columns: 320px 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: 'sidebar main';
	gap: 20px;
	padding: 20px;
	min-height: calc(100vh - 40px);

	&.login-page {
		grid-template-columns: 1fr;
		grid-template-areas: 'main';
		main.box{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			#login-form{
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.row{
					margin:4px;
					input{
						min-width: 300px;
						font-size: 18px;
						border-radius: $mini-radius;
						background-color: $white;
						color: $black;
						padding: $mini-radius;
					}
				}
			}
		}
	}

	aside {
		grid-area: sidebar;
	}

	main {
		grid-area: main;
	}

	.box {
		padding: 20px;
		background-color: rgba($white,.2);
		backdrop-filter: blur(8px);
		border-radius: 8px;
	}

	
}

.sidebar-content {
	display: flex;
	flex-direction: column;
	align-items: center;

	.logo {
		max-width: 100%;
		width: 100px;
	}

	nav {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		ul{
			li{
				padding: 2px 0;
			}
		}
	}
	.logout {
		margin-top: 10px;
		padding-top: 10px;
		border-top: 1px solid #{$white}77;
		color: $white;
	}
}

.message{
	background-color: $white;
	color: $secondary;
	padding: 8px;
	border-left: 4px solid $secondary;
	border-radius: $mini-radius;
	margin: 20px 0;
	overflow: hidden;
	height: 100%;
	transition: all 500ms linear;
	&.message-info {
		color: $info;
		border-left: 4px solid $info;
	}
	&.message-error{
		color: $error;
		border-left: 4px solid $error;
	}
	&.message-success{
		color: $success;
		border-left: 4px solid $success;
	}
	&.remove{
		height: 0;
		padding: 0;
		margin: 0;
	}
}
