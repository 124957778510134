.btn{
	display: inline-block;
	margin: 4px 2px;
	padding: 10px 24px;
	font-size: 1rem;
	background-color: $primary;
	border-radius: $radius;
	border: 2px solid $primary;
	color: $white;
	cursor: pointer;
	font-weight: 500;
	transition: all .5s;
	&:hover{
		border: 2px solid #{$white}77;
	}
}

// Input 
.check_unique {
	--width:40px;
	--height:20px;
	input[type=checkbox] {
		height: 0;
		width: 0;
		visibility: hidden;
	}

	label {
		cursor: pointer;
		text-indent: -9999px;
		width: var(--width);
		height: var(--height);
		background: $secondary;
		display: block;
		border-radius: var(--height);
		position: relative;
		
		&:after {
			content: '';
			position: absolute;
			top: 2px;
			left: 2px;
			width: 16px;
			height: 16px;
			background: #fff;
			border-radius: 16px;
			transition: 0.3s;
		}
	}

	input:disabled+label {
		cursor: default;
		opacity: .5;
	}
	input:checked+label {
		background: $primary;
	}

	input:checked+label:after {
		left: calc(100% - 2px);
		transform: translateX(-100%);
	}

	label:active:after {
		width: 18px;
	}
}
